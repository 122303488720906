import testPhoto from '../assets/images/counter-strike.webp'
import adidas from '../assets/images/adidas.webp'
import coca_cola from '../assets/images/coca_cola.webp'
import puma from '../assets/images/puma.webp'
import hyndai from '../assets/images/hyndai.webp'
import skype from '../assets/images/skype.webp'
import git from '../assets/images/git.webp'
import intel from '../assets/images/intel.webp'
import rolex from '../assets/images/rolex.webp'
import office_photo_1 from '../assets/images/office_photo_1.webp'
import office_photo_2 from '../assets/images/office_photo_2.webp'
import office_photo_3 from '../assets/images/office_photo_3.webp'
import office_photo_4 from '../assets/images/office_photo_4.webp'
import bomb from '../assets/icons/bomb.webp'
import recommendation from '../assets/icons/recommendation.webp'
import customerReview from '../assets/icons/customer-review.webp'

const CONTACTS = {
    whatsApp: 'https://api.whatsapp.com/send?phone=380505305637',
    telegram: 'https://telegram.me/FunnyCOFE',
    email: {
        label: 'EMAIL',
        href: 'mailto:hello@gmail.com?subject=Hello',
        text: 'hello@gmail.com',
    },
    phone: {
        label: 'PHONE',
        href: 'tel:+380505305637',
        text: '+38 (050) 53 05 637',
    },
}

const TEXT_JSON = {
    mainCaption: 'SomniumGame is first company for \n Unreal Engine development in Kharkiv',
    mainSubCaption: 'We provide strong and quality solutions in Game and Web development',
    writeToUsBtn: 'Write to us',
    moreInfoBtn: 'More info',
    whoWeAreCaption: 'Who We Are',
    whoWeAreTopSubCaption:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    whoWeAreBottomSubCaption:
        'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum',
    currentOpeningCaption: 'Current openings',
    allRightsReserved: '© All rights reserved',
    moreDetailBtn: 'More detail',
    messengersCaption: 'MESSENGERS',
    navigation: [
        {
            label: 'Who We Are',
            goto: 'who-we-are',
        },
        {
            label: 'Current Opening',
            goto: 'current-openings',
        },
        {
            label: 'Our cases',
            goto: 'our-cases',
        },
        {
            label: 'Our partners',
            goto: 'our-partners',
        },
    ],
}

const OPENINGS = [
    {
        name: 'Game designer',
        location: 'Kharkiv',
        type: 'Full-time',
        salary: '3000$',
    },
    {
        name: 'Front-end developer',
        location: 'Kharkiv',
        type: 'Full-time',
        salary: '3000$',
    },
    {
        name: 'QA Engineer',
        location: 'Kharkiv',
        type: 'Full-time',
        salary: '3000$',
    },
    {
        name: 'Senior Project Manager',
        location: 'Kharkiv',
        type: 'Full-time',
        salary: '3000$',
    },
]

const WHO_WE_ARE = [
    {
        img: bomb,
        caption: 'Lorem ipsum',
        subCaption: 'Our company has unique experience in full-fledged',
    },
    {
        img: recommendation,
        caption: 'Lorem ipsum',
        subCaption: 'Our company has unique experience in full-fledged',
    },
    {
        img: customerReview,
        caption: 'Lorem ipsum',
        subCaption: 'Our company has unique experience in full-fledged',
    },
    {
        img: bomb,
        caption: 'Lorem ipsum',
        subCaption: 'Our company has unique experience in full-fledged',
    },
]

const APPS = [
    {
        img: testPhoto,
        caption: 'Counter 2Strike',
        subCaption:
            'Evil Defenders – это забавная, чуть ироничная и безумно увлекательная игра \n в жанре Tower Defense с красивой детальной графикой и непростым геймплеем.',
        appsInfo: [
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
            {
                title: 'УСТАНОВОК',
                value: '3M',
            },
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
        ],
    },
    {
        img: testPhoto,
        caption: 'Counter Strike',
        subCaption:
            'Evil Defenders – это забавная, чуть ироничная и безумно увлекательная игра \n в жанре Tower Defense с красивой детальной графикой и непростым геймплеем.',
        appsInfo: [
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
            {
                title: 'УСТАНОВОК',
                value: '3M',
            },
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
        ],
    },
    {
        img: testPhoto,
        caption: 'Counter Strike',
        subCaption:
            'Evil Defenders – это забавная, чуть ироничная и безумно увлекательная игра \n в жанре Tower Defense с красивой детальной графикой и непростым геймплеем.',
        appsInfo: [
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
            {
                title: 'УСТАНОВОК',
                value: '3M',
            },
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
        ],
    },
    {
        img: testPhoto,
        caption: 'Counter Strike',
        subCaption:
            'Evil Defenders – это забавная, чуть ироничная и безумно увлекательная игра \n в жанре Tower Defense с красивой детальной графикой и непростым геймплеем.',
        appsInfo: [
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
            {
                title: 'УСТАНОВОК',
                value: '3M',
            },
            {
                title: 'ОНЛАЙН',
                value: 19000,
            },
        ],
    },
]

const PARTNERS = [
    hyndai,
    git,
    puma,
    rolex,
    skype,
    adidas,
    coca_cola,
    intel,
    adidas,
    hyndai,
    git,
    puma,
]

const OFFICE_PHOTOS = [
    office_photo_1,
    office_photo_2,
    office_photo_3,
    office_photo_4,
    office_photo_3,
    office_photo_2,
    office_photo_4,
    office_photo_1,
]

const RESPONSIVE = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1023 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        partialVisibilityGutter: 50,
        items: 1,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        partialVisibilityGutter: 50,
        items: 1,
    },
}

export default {
    APPS,
    OPENINGS,
    PARTNERS,
    CONTACTS,
    TEXT_JSON,
    RESPONSIVE,
    WHO_WE_ARE,
    OFFICE_PHOTOS,
}
