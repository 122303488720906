import React, { MouseEventHandler } from 'react'
import './index.css'

type Props = {
    text?: string
    type?: 'default' | 'yellow'
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button = ({ text, type = 'default', onClick }: Props) => {
    return (
        <button
            onClick={onClick}
            className={`flex items-center justify-center custom-button-${type}`}
        >
            {text}
        </button>
    )
}

export default Button
