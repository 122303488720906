import React from 'react'

type Props = {
    height?: number
    width?: number
}

const Divider = ({ height = 1, width = 1 }: Props) => (
    <div style={{ width: `${width}vw`, height: `${height}vw` }} />
)

export default Divider
