import React, { CSSProperties, MouseEventHandler } from 'react'
import arrowRight from '../../assets/icons/arrowRight.webp'

type Props = {
    css?: CSSProperties
    onClick: MouseEventHandler<HTMLButtonElement>
}

const NextButton = ({ css, onClick }: Props) => {
    return (
        <button
            onClick={onClick}
            className="hide-mobile absolute right-0 h-full z-10 flex items-center justify-center"
            style={{
                width: '6vw',
                borderRadius: 0,
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                border: 'none',
                background: 'linear-gradient(to left, #F5CA18, #E76F00)',
                ...css,
            }}
        >
            <img alt="img" src={arrowRight} className="arrow" />
        </button>
    )
}

export default NextButton
