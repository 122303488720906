import axios from 'axios'
import constants from '../utils/constants'
const { OPENINGS, APPS, OFFICE_PHOTOS, WHO_WE_ARE, TEXT_JSON, CONTACTS } =
    constants

const debugUrl = false
const baseUrl = (debugUrl ? 'http://localhost/api/' : 'https://office.somniumgame.com/api/')

const getApps = (callback: Function) => {
    axios
        .get(baseUrl + 'get_projects')
        .then(function (response) {
            callback(response.data);
            //callback(APPS)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getOpenings = (callback: Function) => {
    axios
        .get(baseUrl + 'get_openings')
        .then(function (response) {
            callback(response.data);
            // callback(OPENINGS)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getOfficePhotos = (callback: Function) => {
    axios
        .get(baseUrl + 'get_office')
        .then(function (response) {
            callback(response.data);
            //callback(OFFICE_PHOTOS)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getWhoWeAre = (callback: Function) => {
    axios
        .get(baseUrl + 'get_who-we-are')
        .then(function (response) {
            callback(response.data);
            //callback(WHO_WE_ARE)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getTextJson = (callback: Function) => {
    axios
        .get(baseUrl + 'get_translation/en')
        .then(function (response) {
            callback(response.data[0]);
            //callback(TEXT_JSON)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getContacts = (callback: Function) => {
    axios
        .get(baseUrl + 'get_contacts')
        .then(function (response) {
            callback(response.data);
            //callback(CONTACTS)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getPartners = (callback: Function) => {
    axios
        .get(baseUrl + 'get_partners')
        .then(function (response) {
            callback(response.data);
            //callback(CONTACTS)
        })
        .catch(function (error) {
            console.warn(error);
        })
}

const getLocations = (callback: Function) => {
    axios
        .get(baseUrl + 'get_locations')
        .then(function (response) {
            callback(response.data);
        })
        .catch(function (error) {
            console.warn(error);
        })
}

export default {
    getApps,
    getTextJson,
    getContacts,
    getOpenings,
    getWhoWeAre,
    getOfficePhotos,
    getPartners,
    getLocations,
}
