import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = () => (
    <div className="spinner-container">
        <div className="spinner-logo">
            <div className="loading-spinner" />
        </div>
    </div>
)

export default LoadingSpinner