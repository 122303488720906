import React, { CSSProperties, MouseEventHandler } from 'react'
import arrowLeft from '../../assets/icons/arrowLeft.webp'

type Props = {
    css?: CSSProperties
    onClick: MouseEventHandler<HTMLButtonElement>
}

const PrevButton = ({ css, onClick }: Props) => {
    return (
        <button
            onClick={onClick}
            className="hide-mobile absolute left-0 h-full z-10 gradient flex items-center justify-center"
            style={{
                width: '6vw',
                borderRadius: 0,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                border: 'none',
                ...css,
            }}
        >
            <img alt="img" src={arrowLeft} className="arrow" />
        </button>
    )
}

export default PrevButton
