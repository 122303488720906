import React, { createRef, LegacyRef, useEffect, useState } from 'react'
import mainLogo from './assets/images/main-logo.webp'
import arrowDown from './assets/images/arrow-down.webp'
import location from './assets/icons/location.webp'
import fullTime from './assets/icons/full-time.webp'
import salary from './assets/icons/salary.webp'
import whatsApp from './assets/icons/whats-app.webp'
import telegram from './assets/icons/telegram.webp'
import divider from './assets/icons/divider.webp'
import burger from './assets/icons/burger.webp'
import close from './assets/icons/close.webp'
import Divider from './components/divider'
import LoadingSpinner from './components/spinner/LoadingSpinner'
import Button from './components/Button'
import constants from './utils/constants'
import MultiCarousel from 'react-multi-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'react-multi-carousel/lib/styles.css'
import { Carousel } from 'react-responsive-carousel'
import NextButton from './components/NextButton'
import PrevButton from './components/PrevButton'
import MapSection from './components/map/Map'
import API from './api'
import {
    Opening,
    Contacts,
    TextJson,
    WhoWeAreItem,
    AppItem,
    Location
} from './utils/types'

const { RESPONSIVE } = constants

function App() {
    let ref = createRef<MultiCarousel | undefined>()
    const [textJson, setTextJson] = useState<TextJson>()
    const [contacts, setContacts] = useState<Contacts>()
    const [currentIndex, setSlide] = useState(0)
    const [apps, setApps] = useState<AppItem[]>([])
    const [isNavbarOpen, setNavbar] = useState(false)
    const [openings, setOpenings] = useState<Opening[]>([])
    const [whoWeAre, setWhoWeAre] = useState<WhoWeAreItem[]>([])
    const [partners, setPartners] = useState<string[]>([])
    const [locations, setLocations] = useState<Location>()
    const [officePhotos, setOfficePhotos] = useState<string[]>([])

    useEffect(() => {
        API.getApps((data: AppItem[]) => {
            setApps(data)
        })
        API.getOpenings((data: Opening[]) => {
            setOpenings(data)
        })
        API.getOfficePhotos((data: string[]) => {
            setOfficePhotos(data)
        })
        API.getWhoWeAre((data: WhoWeAreItem[]) => {
            setWhoWeAre(data)
        })
        API.getTextJson((data: TextJson) => {
            setTextJson(data)
        })
        API.getContacts((data: Contacts) => {
            setContacts(data)
        })
        API.getPartners((data: string[]) => {
            setPartners(data)
        })
        API.getLocations((data: Location) => {
            data.coordinates[0] = +data.coordinates[0];
            data.coordinates[1] = +data.coordinates[1];
            setLocations(data)
        })
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setNavbar(false)
        })
    }, [])

    const handleToggle = () => {
        setNavbar((prev) => !prev)
    }

    const renderIndicator = (
        clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
        isSelected: boolean,
        index: number
    ) => {
        const onClick = () => setSlide(index)

        return (
            <div
                key={`indicator-${index}`}
                className="rounded-full inline-flex items-center justify-center mobile-indicator"
                style={{
                    minWidth: '1.8vw',
                    minHeight: '1.8vw',
                    border: `2px solid ${
                        isSelected ? '#EE9C0C' : 'transparent'
                    }`,
                }}
                onClick={onClick}
            >
                <div className="flex items-center justify-center bg-transparent">
                    <div
                        className="rounded-full"
                        style={{
                            minWidth: '0.4vw',
                            minHeight: '0.4vw',
                            backgroundColor: isSelected ? '#EE9C0C' : '#9199A4',
                        }}
                    />
                </div>
            </div>
        )
    }

    const CustomDot = ({ ...rest }) => {
        const { onClick, index, active } = rest
        return (
            <div
                key={`indicator-${index}`}
                className="rounded-full inline-flex items-center justify-center mobile-indicator"
                style={{
                    minWidth: '1.8vw',
                    minHeight: '1.8vw',
                    border: `2px solid ${active ? '#EE9C0C' : 'transparent'}`,
                }}
                onClick={onClick}
            >
                <div className="flex items-center justify-center bg-transparent">
                    <div
                        className="rounded-full"
                        style={{
                            minWidth: '0.4vw',
                            minHeight: '0.4vw',
                            backgroundColor: active ? '#EE9C0C' : '#9199A4',
                        }}
                    />
                </div>
            </div>
        )
    }

    const renderNavigation = () =>
        textJson?.navigation?.map((item) => (
            <div
                key={item.label}
                className="h-full flex items-center justify-center relative cursor-pointer"
                onClick={() => {
                    const targetEl = document.getElementById(item.goto)
                    targetEl?.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    })
                    handleToggle()
                }}
            >
                <li>{item.label}</li>
                <div className="li-backdrop absolute bottom-0 left-0" />
            </div>
        ))

    const scrollToForm = () => {
        const form = window.innerWidth <= 768 ? 'mobile-form' : 'desktop-form'
        const targetEl = document.getElementById(form)
        targetEl?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }

    const WHATS_APP_LINK = () => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={contacts?.whatsApp} target="_blank">
            <img alt="img" src={whatsApp} />
        </a>
    )

    const TELEGRAM_LINK = () => (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={contacts?.telegram} target="_blank">
            <img alt="img" src={telegram} />
        </a>
    )

    const PHONE_LINK = () => (
        <a href={contacts?.phone?.href}>{contacts?.phone?.text}</a>
    )

    const EMAIL_LINK = () => (
        <a href={contacts?.email?.href}>{contacts?.email?.text}</a>
    )

    return (
        <div className="main-wrapper w-full h-full">
            { textJson && contacts && whoWeAre && apps && officePhotos && openings && locations ? null : <LoadingSpinner /> }
            <header className="flex w-full justify-between items-center hide-mobile">
                <div className="flex items-center">
                    <img src={mainLogo} className="logo" alt="logo" />
                </div>
                <ul>{renderNavigation()}</ul>
                <Button text={textJson?.writeToUsBtn} onClick={scrollToForm} />
            </header>
            <header className="flex w-full justify-between items-center hide-desktop">
                <img src={mainLogo} className="logo" alt="logo" />
                <button onClick={handleToggle} className="burger">
                    <img
                        src={isNavbarOpen ? close : burger}
                        className="h-full w-full"
                        alt="nav bar button"
                    />
                </button>
                <nav
                    className="nav-bar h-full"
                    style={{ display: isNavbarOpen ? 'flex' : 'none' }}
                >
                    <ul>{renderNavigation()}</ul>
                </nav>
            </header>
            <div className="hide-mobile">
                <Divider height={11} />
            </div>
            <div className="hide-desktop">
                <Divider height={25} />
            </div>
            <div className="content-wrapper h-full flex items-center justify-center flex-col">
                <div
                    className="flex flex-col items-center justify-start"
                    style={{ minHeight: 'calc(100vh - 18vw)' }}
                >
                    <h1 className="whitespace-pre-line text-center mobile-h1">
                        {textJson?.mainCaption}
                    </h1>
                    <Divider height={1} />
                    <span className="mobile-span">
                        {textJson?.mainSubCaption}
                    </span>
                    <Divider height={3} />
                    <div className="flex btn-box">
                        <Button
                            text={textJson?.moreInfoBtn}
                            onClick={() => {
                                const targetEl = document.getElementById('who-we-are')
                                targetEl?.scrollIntoView({
                                    block: 'start',
                                    behavior: 'smooth',
                                })
                                handleToggle()
                            }}
                            type="yellow"
                        />
                        <Divider width={3} />
                        <Button
                            text={textJson?.writeToUsBtn}
                            onClick={scrollToForm}
                        />
                    </div>
                    <div className="hide-desktop">
                        <Divider height={10} />
                    </div>
                    <img alt="img" src={arrowDown} className="main-arrow" />
                </div>
                <div className="gradient-divider hide-desktop" />
                <div
                    id="who-we-are" className="who-we-are-box w-full h-full flex items-center justify-center relative">
                    <div
                        className="who-we-are-backdrop w-full h-full flex items-center justify-center relative"
                    >
                        <div className="who-we-are w-full h-full flex-col">
                            <div className="flex w-full">
                                <h2>{textJson?.whoWeAreCaption}</h2>
                                <div>
                                    <span className="whitespace-pre-line block mb-8">
                                        {textJson?.whoWeAreTopSubCaption}
                                    </span>
                                    <span className="whitespace-pre-line">
                                        {textJson?.whoWeAreBottomSubCaption}
                                    </span>
                                </div>
                            </div>
                            <Divider height={3} />
                            <div className="w-full flex justify-between items-center">
                                {whoWeAre.map((item, i) => (
                                    <div
                                        className="flex mobile-item"
                                        key={`we-are-${i}`}
                                        style={{
                                            marginRight: i !== 3 ? 15 : 0,
                                        }}
                                    >
                                        <div className="item flex items-center justify-center">
                                            <img alt="img" src={item.img} />
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="item-caption">
                                                {item.caption}
                                            </span>
                                            <span className="item-sub-caption">
                                                {item.subCaption}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gradient-divider hide-desktop" />
                <div className="w-full office-photos-wrapper">
                    <div className="w-full relative office-photos">
                        <PrevButton
                            css={{
                                height: 'calc(100% - 14vw)',
                            }}
                            onClick={() => {
                                // @ts-ignore
                                ref.current?.previous()
                            }}
                        />
                        <MultiCarousel
                            infinite
                            showDots
                            renderDotsOutside
                            ref={ref as LegacyRef<MultiCarousel>}
                            centerMode={window.innerWidth <= 768}
                            customDot={<CustomDot />}
                            arrows={false}
                            responsive={RESPONSIVE}
                        >
                            {officePhotos.map((p, i) => (
                                <img alt="img" key={`office-photo-${i}`} src={p} />
                            ))}
                        </MultiCarousel>
                        <NextButton
                            css={{
                                top: '7vw',
                                height: 'calc(100% - 14vw)',
                            }}
                            onClick={() => {
                                // @ts-ignore
                                ref.current?.next()
                            }}
                        />
                    </div>
                </div>
                <div
                    id="current-openings"
                    className="current-openings w-full flex justify-start flex-col"
                >
                    <Divider height={5} />
                    <h2 className="uppercase">
                        {textJson?.currentOpeningCaption}
                    </h2>
                    <div className="flex flex-col w-full openings-wrapper">
                        {openings.map((opening, index) => (
                            <div
                                key={opening.name}
                                className="flex flex-col items-center justify-center"
                            >
                                <div className="w-full flex items-center justify-between opening-item">
                                    <span className="uppercase opening-caption font-bold w-4/12">
                                        {opening.name}
                                    </span>
                                    <div className="w-2/12 flex items-center justify-center">
                                        <img alt="img" src={location} />
                                        <span>{opening.location}</span>
                                    </div>
                                    <div className="w-2/12 flex items-center justify-center">
                                        <img alt="img" src={fullTime} />
                                        <span>{opening.type}</span>
                                    </div>
                                    <div className="w-2/12 flex items-center justify-center">
                                        <img alt="img" src={salary} />
                                        <span>{opening.salary}$</span>
                                    </div>
                                    <Button text={textJson?.moreDetailBtn}
                                            onClick={scrollToForm}
                                    />
                                </div>
                                {index !== openings.length - 1 ? (
                                    <div className="openings-divider hide-mobile" />
                                ) : null}
                            </div>
                        ))}
                    </div>
                    <Divider height={5} />
                    <div className="relative" id="our-cases">
                        <PrevButton
                            onClick={() => {
                                const nextSlide =
                                    currentIndex === 0
                                        ? apps.length - 1
                                        : currentIndex - 1
                                setSlide(nextSlide)
                            }}
                        />
                        <NextButton
                            onClick={() => {
                                const nextSlide =
                                    currentIndex === apps.length - 1
                                        ? 0
                                        : currentIndex + 1
                                setSlide(nextSlide)
                            }}
                        />
                        <Carousel
                            showStatus={false}
                            showArrows={false}
                            showThumbs={false}
                            selectedItem={currentIndex}
                            renderIndicator={renderIndicator}
                            className="carousel-main-wrapper"
                            preventMovementUntilSwipeScrollTolerance
                        >
                            {apps.map((p, i) => (
                                <div
                                    className="flex items-center justify-center relative w-full h-full"
                                    key={`app-${i}`}
                                >
                                    <div className="flex items-center justify-start flex-col absolute pt-1 text-shadowed">
                                        <h2>{p.caption}</h2>
                                        <h3 className="whitespace-pre-line">
                                            {p.subCaption}
                                        </h3>
                                        <Divider height={3} />
                                        <div className="flex items-center justify-center app-info-wrapper">
                                            {p.appsInfo.map((info, index) => (
                                                <React.Fragment
                                                    key={`${info.title}-${i}-${index}`}
                                                >
                                                    <div className="app-info-item">
                                                        <h1>{info.value}</h1>
                                                        <h3>{info.title}</h3>
                                                    </div>
                                                    {index !== 2 ? (
                                                        <img
                                                            alt="img"
                                                            src={divider}
                                                            style={{
                                                                width: 1,
                                                                height: 'auto',
                                                                margin: '0 25px',
                                                            }}
                                                        />
                                                    ) : null}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <Divider height={3} />
                                    </div>
                                    <img alt="img" src={p.img} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div id="our-partners" className="partners-wrapper w-full">
                        {partners.map((p, i) => (
                            <div
                                className="flex items-center justify-center"
                                key={`${i}-partner`}
                            >
                                <img src={p} alt="img" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="contact-us flex justify-end w-full hide-mobile">
                    <form id="desktop-form">
                        <div>
                            <span>{contacts?.phone?.label}</span>
                            {PHONE_LINK()}
                        </div>
                        <div className="divider" />
                        <div>
                            <span>{contacts?.email?.label}</span>
                            {EMAIL_LINK()}
                        </div>
                        <div className="divider" />
                        <div>
                            <span>{textJson?.messengersCaption}</span>
                            <div className="flex items-center">
                                <div className="messenger-item flex items-center justify-center gradient">
                                    {WHATS_APP_LINK()}
                                </div>
                                <div className="messenger-item flex items-center justify-center gradient">
                                    {TELEGRAM_LINK()}
                                </div>
                            </div>
                        </div>
                        <div className="divider" />
                        <div className="rights-wrapper">
                            <img alt="img" src={mainLogo} className="logo" />
                            <span>{textJson?.allRightsReserved}</span>
                        </div>
                    </form>
                </div>
                <div className="contact-us hide-desktop" />
                <form id="mobile-form" className="hide-desktop">
                    <div>
                        <span>{contacts?.phone?.label}</span>
                        {PHONE_LINK()}
                    </div>
                    <div className="divider" />
                    <div>
                        <span>{contacts?.email?.label}</span>
                        {EMAIL_LINK()}
                    </div>
                    <div className="divider" />
                    <div>
                        <span>{textJson?.messengersCaption}</span>
                        <div className="flex items-center">
                            <div className="messenger-item flex items-center justify-center gradient">
                                {WHATS_APP_LINK()}
                            </div>
                            <div className="messenger-item flex items-center justify-center gradient">
                                {TELEGRAM_LINK()}
                            </div>
                        </div>
                    </div>
                    <div className="divider" />
                    <div className="rights-wrapper">
                        <img alt="img" src={mainLogo} className="logo" />
                        <span>{textJson?.allRightsReserved}</span>
                    </div>
                </form>
            </div>
            { locations ?
                <MapSection location={locations} zoomLevel={16} />
                : null
            }
        </div>
    )
}

export default App
